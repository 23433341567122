export function formatNumber(num: number, decimals = 0): string {
	return new Intl.NumberFormat('sv', {
		maximumFractionDigits: decimals,
		minimumFractionDigits: decimals,
	}).format(num);
}

export function formatNumberNullable(num, decimals = 0, fallback = ''): string {
	if (typeof num === 'number') {
		return formatNumber(num, decimals);
	}

	return fallback;
}

export function formatCurrency(num: number, decimals = 0): string {
	return formatNumber(num, decimals) + ' kr';
}

export function formatCurrencyNullable(num, decimals = 0, fallback = ''): string {
	return formatNumberNullable(num, decimals, fallback) + ' kr';
}
